import { AxiosResponse } from "axios";
import { IIndustry } from "../modules/industries/interface";
import { urlMaker } from "../utils";
import { httpClient } from "./config";

export const industryApi = {
  getIndustries: (type: string, filters?: { [key: string]: any }) =>
    httpClient.get(urlMaker("/industries/industries/", { ...filters, type })),

  createIndustry: (data: any) => httpClient.post("/industries/industries/industry/", data),

  updateIndustry: (id: string, data: any) =>
    httpClient.put(`/industries/industries/${id}/`, data),

  getIndustryById: (id: string): Promise<AxiosResponse<IIndustry>> =>
    httpClient.get(`/industries/industries/${id}/`),

  deleteIndustry: (id: string | number) =>
    httpClient.delete(`/industries/industries/${id}/`),
};
