import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { authApi } from "../../api";
import { __access_token, __refresh_token } from "../../constants/Values";

interface IUserDetails {
  loading: "idle" | "loading" | "no_user" | "active";
  user_data?: {
    id: number;
    first_name: string;
    file_type: string;
    last_name: string;
    username: string;
    email: string;
    role: string;
    segment:string;
  };
}

const initialState: IUserDetails = {
  loading: "idle",
  user_data: undefined,
};

export const getUserAsync = createAsyncThunk("user_credentials/get_user", () =>
  authApi.getMe().then((response) => response.data)
);

const userSlice = createSlice({
  name: "user_credentials",
  initialState,
  reducers: {
    logout: () => {
      Cookies.remove(__access_token);
      Cookies.remove(__refresh_token);
      window.location.href = "/";
    },
  },
  extraReducers: {
    [getUserAsync.pending.type]: (state) => {
      state.loading = "loading";
    },
    [getUserAsync.fulfilled.type]: (
      state,
      action: PayloadAction<IUserDetails["user_data"]>
    ) => {
      state.loading = "active";
      state.user_data = action.payload;
    },
    [getUserAsync.rejected.type]: (state) => {
      state.loading = "no_user";
    },
  },
});

export const { reducer: userReducer } = userSlice;
export const { logout } = userSlice.actions;
