import { AxiosResponse } from "axios";
import {
  ExperimentalRatingPersistence,
  ExperimentalValues,
  IQualitativeParamApi,
  IQuantitativeParam,
} from "../modules/ratings/interface";
import { urlMaker } from "../utils";
import { httpClient } from "./config";

export const ratingApi = {
  getRatings: async (filters?: object): Promise<AxiosResponse<any>> =>
    httpClient.get(urlMaker("/ratings", filters)),

  getRatingValues: async () =>
    httpClient.get("/companies/companies_rating_values"),

  mraUpload: async (
    id: string | number,
    file: any
  ): Promise<AxiosResponse<IQuantitativeParam[]>> =>
    httpClient.post(`/companies/mra_upload/${id}/`, file),

  getFinanceDate: async (
    companyId: string | number,
    financeFile: any
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData();
    formData.append("company_id", companyId.toString());
    formData.append("finance_file", financeFile);
    return httpClient.post("/ratings/get_finance_date/", formData);
  },

  getQualitativeParams: (
    id: string | number
  ): Promise<AxiosResponse<IQualitativeParamApi[]>> =>
    httpClient.get(`/companies/qualitative-params/${id}`),

  getQualitativeParamValues: (
    id: string | number
  ): Promise<AxiosResponse<any>> =>
    httpClient.get(`/companies/get-qualitative-rating-values/${id}/`),

  saveQuantitativeParams: (dt: any) =>
    httpClient.post("/companies/save_qualitative_params/", dt),

  getGovernment: async () => httpClient.get("/ratings/governments/"),

  updateGovernment: async (body: FormData) =>
    httpClient.post(`/companies/rating-government/`, body),

  getQuantitativeParams: async (
    id: string | number
  ): Promise<AxiosResponse<IQuantitativeParam[]>> =>
    httpClient.get(`/companies/quantitative-params/${id}/`),

  getOrc: async () => httpClient.get("/companies/orc/"),

  updateOrc: async (body: FormData) =>
    httpClient.post("/companies/rating-orc/", body),

  getRatingById: async (id: string | number): Promise<AxiosResponse<any>> =>
    httpClient.get(`/companies/ratings/${id}`),

  getGovernmentRatingsByRatingId: async (id: string | number) =>
    httpClient.get(`/companies/get-government-rating-values/${id}/`),

  getOcrRatingValuesByRatingValues: async (id: string | number) =>
    httpClient.get(`/companies/get-orc-rating-values/${id}/`),

  getOrcRating: async (id: string | number): Promise<AxiosResponse<number>> =>
    httpClient.get(`/companies/get-rating-orc/${id}/`),

  getPrimaryRating: async (
    id: string | number
  ): Promise<AxiosResponse<number>> =>
    httpClient.get(`/companies/get-rating-quantitative/${id}/`),

  getGovernmentRating: async (
    id: string | number
  ): Promise<AxiosResponse<number>> =>
    httpClient.get(`/companies/get-rating-government/${id}/`),

  getFinalRating: async (id: string | number): Promise<AxiosResponse<number>> =>
    httpClient.get(`/companies/get_final_rating/${id}/`),

  getCountryRating: async (id: string | number): Promise<AxiosResponse<any>> =>
    httpClient.get(`/companies/create_country_rating/${id}/`),

  calculateCalibrationRating: async (
    id: string | number
  ): Promise<AxiosResponse<number>> =>
    httpClient.get(`/companies/calibration/${id}/`),

  getCalibrationRating: async (id: string | number) =>
    httpClient.get(`/companies/get_calibration_rating/${id}/`),

  sendToValidator: async (
    id: string | number,
    additional_note: string
  ): Promise<AxiosResponse<any>> =>
    httpClient.put(`/ratings/rating-status/${id}/?status=waiting`, {
      additional_note,
    }),

  approveRating: async (
    id: string | number,
    additional_note: string
  ): Promise<AxiosResponse<any>> =>
    httpClient.put(`/ratings/rating-status/${id}/?status=approved`, {
      additional_note,
    }),

  declineRating: async (
    id: string | number,
    additional_note: string
  ): Promise<AxiosResponse<any>> =>
    httpClient.put(`/ratings/rating-status/${id}/?status=declined`, {
      additional_note,
    }),

  saveAsDraft: async (
    id: string | number,
    additional_note: string
  ): Promise<AxiosResponse<any>> =>
    httpClient.put(`/ratings/rating-status/${id}/?status=draft`, {
      additional_note,
    }),

  //TESTING
  getExperimentalValue: (
    id: string | number,
    type: string
  ): Promise<AxiosResponse<ExperimentalValues>> =>
    httpClient.get(urlMaker(`/industries/industries/${id}/`, { type })),

  getExperimentalRatingById: (
    id: string | number
  ): Promise<AxiosResponse<ExperimentalRatingPersistence>> =>
    httpClient.get(`/ratings/${id}/grades/`),

  getSupportFiles: (id: string | number) =>
    httpClient.get(`/ratings/supportfiles/${id}/`),

  addSupportFiles: (id: string | number, data: FormData) =>
    httpClient.post(`/ratings/supportfiles/${id}/`, data),

  calculateDowngrade: (id: string | number, notch: number | string) =>
    httpClient.post(`/ratings/downgrade-rate/${id}/`, { notch }),

  getSupportFileCategories: () => httpClient.get("/ratings/file-category/"),

  collectiveDowngrade: () => httpClient.post("/ratings/collective-downgrade/"),

  destroyRating: (id: string | number) =>
    httpClient.delete(`/ratings/${id}/destroy_draft/`),

  notifyValidator: (id: string | number) =>
    httpClient.post(`/companies/notify-validator/${id}/`),

  createComment: (comment: string, id: number) =>
    httpClient.post(`/ratings/comments/${id}/`, { comment }),

  updateComment: (ratingId: number, commentId: number, comment: string) =>
    httpClient.put(`/ratings/comments/${ratingId}/?comment_id=${commentId}`, {
      comment,
    }),

  getComments: (id: number) => httpClient.get(`/ratings/comments/${id}/`),

  deleteComment: (id: number | string, comment_id: number) =>
    httpClient.delete(`/ratings/comments/${id}/?comment_id=${comment_id}`),

  getValidators: (id: number | string) =>
    httpClient.get(`/companies/notify-validator/${id}/`),

  addValidators: (id: string | number, users: (string | number)[]) =>
    httpClient.post(`/companies/notify-validator/${id}/`, { users }),
  deleteSupportFile: (rateId: string | number, supportFileId: string | number) =>
    httpClient.post(`/ratings/supportfiles-delete/${rateId}/${supportFileId}/`),

};
